// extracted by mini-css-extract-plugin
export var gallerySection = "teamlife-module--gallerySection--cfBZ1";
export var galleryTitle = "teamlife-module--galleryTitle---kG3G";
export var hiddenTab = "teamlife-module--hiddenTab--44IwZ";
export var meetTeamDescriptionImageWrapper = "teamlife-module--meetTeamDescriptionImageWrapper--RXAXc";
export var meetTeamDescriptionText = "teamlife-module--meetTeamDescriptionText--s8g-m";
export var meetTeamDescriptionWrapper = "teamlife-module--meetTeamDescriptionWrapper--UHNBC";
export var meetTeamList = "teamlife-module--meetTeamList--88cEo";
export var meetTeamListItem = "teamlife-module--meetTeamListItem--zaGws";
export var meetTeamListItemActive = "teamlife-module--meetTeamListItemActive--8A4Sw";
export var meetTeamListItemBtn = "teamlife-module--meetTeamListItemBtn--AUVUX";
export var meetTeamListItemWrapper = "teamlife-module--meetTeamListItemWrapper--sSBip";
export var meetTeamSection = "teamlife-module--meetTeamSection--+iZtl";
export var meetTeamTitle = "teamlife-module--meetTeamTitle--QX50c";
export var meetTeamTreeImage = "teamlife-module--meetTeamTreeImage--XMqin";
export var meetTeamTwoImage = "teamlife-module--meetTeamTwoImage--VXLer";
export var meetTeamWrapper = "teamlife-module--meetTeamWrapper--7bg6g";
export var seeYouSection = "teamlife-module--seeYouSection--+iowN";
export var seeYouSectionWrapper = "teamlife-module--seeYouSectionWrapper--uLjnH";
export var seeYouText = "teamlife-module--seeYouText--gZ8gf";
export var seeYouTitle = "teamlife-module--seeYouTitle--WJJ57";
export var seeYouTitleMark = "teamlife-module--seeYouTitleMark--G4IWh";
export var teamSection = "teamlife-module--teamSection--IAEtD";
export var teamSectionWrapper = "teamlife-module--teamSectionWrapper--H-09S";
export var teamText = "teamlife-module--teamText--Vw8I9";
export var teamTitle = "teamlife-module--teamTitle--Pez7C";
export var teamTreeLayout = "teamlife-module--teamTreeLayout--P-zvW";
export var weSayList = "teamlife-module--weSayList--NcFTB";
export var weSayListItem = "teamlife-module--weSayListItem--3wPtM";
export var weSayListItemTitle = "teamlife-module--weSayListItemTitle--3WIEB";
export var weSayTitle = "teamlife-module--weSayTitle--mXs+u";