import { Swiper as SwiperClass } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/a11y";
import "swiper/css";
import { Grid, A11y, Pagination, Navigation } from "swiper";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

import { useWindowSize } from "@/hooks";
import { Button } from "@/components/ui";
import SecondaryArrowIcon from "@/assets/svgs/icons/arrow-secondary.svg";

import "./slider.css";
import * as styles from "./gallery.module.css";

const MOBILE_SCREEN = 600;

const initialRows = {
  rows: 2,
};

const initialPagination = {
  dynamicBullets: true,
};

type GalleryQueryType = {
  team1: FileNode;
  team2: FileNode;
  team3: FileNode;
  team4: FileNode;
  team5: FileNode;
  team6: FileNode;
  team7: FileNode;
  team8: FileNode;
  team9: FileNode;
  team10: FileNode;
  team11: FileNode;
  team12: FileNode;
  team13: FileNode;
  team14: FileNode;
  team15: FileNode;
  team16: FileNode;
  team17: FileNode;
  team18: FileNode;
  team19: FileNode;
  team20: FileNode;
  team21: FileNode;
  team22: FileNode;
};

export const galleryQuery = graphql`
  query {
    team1: file(relativePath: { eq: "teamlife/gallery/team-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team2: file(relativePath: { eq: "teamlife/gallery/team-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team3: file(relativePath: { eq: "teamlife/gallery/team-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 600, placeholder: BLURRED)
      }
    }
    team4: file(relativePath: { eq: "teamlife/gallery/team-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 294, placeholder: BLURRED)
      }
    }
    team5: file(relativePath: { eq: "teamlife/gallery/team-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team6: file(relativePath: { eq: "teamlife/gallery/team-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team7: file(relativePath: { eq: "teamlife/gallery/team-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 600, placeholder: BLURRED)
      }
    }
    team8: file(relativePath: { eq: "teamlife/gallery/team-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 294, placeholder: BLURRED)
      }
    }
    team9: file(relativePath: { eq: "teamlife/gallery/team-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team10: file(relativePath: { eq: "teamlife/gallery/team-10.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team11: file(relativePath: { eq: "teamlife/gallery/team-11.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 294, placeholder: BLURRED)
      }
    }
    team12: file(relativePath: { eq: "teamlife/gallery/team-12.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 600, placeholder: BLURRED)
      }
    }
    team13: file(relativePath: { eq: "teamlife/gallery/team-13.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team14: file(relativePath: { eq: "teamlife/gallery/team-14.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team15: file(relativePath: { eq: "teamlife/gallery/team-15.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 600, placeholder: BLURRED)
      }
    }
    team16: file(relativePath: { eq: "teamlife/gallery/team-16.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 294, placeholder: BLURRED)
      }
    }
    team17: file(relativePath: { eq: "teamlife/gallery/team-17.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team18: file(relativePath: { eq: "teamlife/gallery/team-18.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team19: file(relativePath: { eq: "teamlife/gallery/team-19.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 600, placeholder: BLURRED)
      }
    }
    team20: file(relativePath: { eq: "teamlife/gallery/team-20.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 468, height: 294, placeholder: BLURRED)
      }
    }
    team21: file(relativePath: { eq: "teamlife/gallery/team-21.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
    team22: file(relativePath: { eq: "teamlife/gallery/team-22.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 470, height: 447, placeholder: BLURRED)
      }
    }
  }
`;

export const Gallery = () => {
  const data: GalleryQueryType = useStaticQuery(galleryQuery);
  const swiperRef = useRef<SwiperClass>();
  const { t } = useTranslation();
  const [rows, setRows] = useState(initialRows);
  const { width } = useWindowSize();
  const isMobile = width < MOBILE_SCREEN;

  useEffect(() => {
    setRows({ rows: isMobile ? 1 : initialRows.rows });
  }, [isMobile]);

  return (
    <>
      <Swiper
        slidesPerView={isMobile ? 1 : 3}
        grid={rows}
        pagination={isMobile && initialPagination}
        scrollbar={{ draggable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        rewind
        spaceBetween={16}
        modules={[Grid, A11y, Pagination, Navigation]}
        className={styles.gallery}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {Object.values(data).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index}>
            <GatsbyImage className={styles.galleryImage} image={getImage(item)} alt="" />
          </SwiperSlide>
        ))}
        {isMobile && (
          <>
            <span className="swiper-button-prev">
              <SecondaryArrowIcon />
            </span>
            <span className="swiper-button-next">
              <SecondaryArrowIcon />
            </span>
          </>
        )}
      </Swiper>
      {!isMobile && (
        <Button
          className={styles.galleryButton}
          iconClassName={styles.galleryButtonIcon}
          variant="secondary"
          icon={<SecondaryArrowIcon />}
          onClick={() => swiperRef.current.slideNext()}
        >
          {t("we_not_just_colleagues.next_button_text")}
        </Button>
      )}
    </>
  );
};
