// i18next-extract-mark-ns-start teamlife-page

import React, { FC, useMemo, useState } from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import cn from "classnames";

import { useWindowSize } from "@/hooks";
import { Gallery } from "@/components/ui/teamlife/gallery";
import { CareerBlock } from "@/components/ui";
import { Layout } from "@/components/layout";
import TeamLifeIllustration from "@/assets/svgs/teamlife/teamlife-illstr.svg";
import CirclesIllustration from "@/assets/svgs/teamlife/circles.svg";
import SecondaryArrowIcon from "@/assets/svgs/icons/arrow-secondary.svg";

import * as styles from "./teamlife.module.css";

const LAPTOP_SCREEN = 1180;
const MOBILE_SCREEN = 600;

type TeamLifePageProps = {
  data: {
    office3: FileNode;
    office2: FileNode;
    office1: FileNode;
    party1: FileNode;
    party2: FileNode;
    party2Mobile: FileNode;
    charity2: FileNode;
    charity1: FileNode;
    charity1Mobile: FileNode;
    relax: FileNode;
  };
};

const TeamLifePage: FC<TeamLifePageProps> = ({ data }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const { width } = useWindowSize();

  const isLaptop = width < LAPTOP_SCREEN;
  const isMobile = width < MOBILE_SCREEN;

  const imageBlocks = useMemo(() => {
    return (
      <>
        <div className={cn({ [styles.hiddenTab]: activeTab !== 0 })}>
          <div className={cn(styles.meetTeamDescriptionImageWrapper, styles.teamTreeLayout)}>
            <GatsbyImage className={styles.meetTeamTreeImage} image={getImage(data.office3)} alt="" />
            <GatsbyImage className={styles.meetTeamTreeImage} image={getImage(data.office2)} alt="" />
            <GatsbyImage className={styles.meetTeamTreeImage} image={getImage(data.office1)} alt="" />
          </div>
        </div>
        <div className={cn({ [styles.hiddenTab]: activeTab !== 1 })}>
          <div className={styles.meetTeamDescriptionImageWrapper}>
            <GatsbyImage className={styles.meetTeamTwoImage} image={getImage(data.party1)} alt="" />
            <GatsbyImage
              className={styles.meetTeamTwoImage}
              image={!isMobile ? getImage(data.party2) : getImage(data.party2Mobile)}
              alt=""
            />
          </div>
        </div>
        <div className={cn({ [styles.hiddenTab]: activeTab !== 2 })}>
          <div className={styles.meetTeamDescriptionImageWrapper}>
            <GatsbyImage className={styles.meetTeamTwoImage} image={getImage(data.charity2)} alt="" />
            <GatsbyImage
              className={styles.meetTeamTwoImage}
              image={!isMobile ? getImage(data.charity1) : getImage(data.charity1Mobile)}
              alt=""
            />
          </div>
        </div>
        <div className={cn({ [styles.hiddenTab]: activeTab !== 3 })}>
          <GatsbyImage image={getImage(data.relax)} alt="" />
        </div>
      </>
    );
  }, [
    activeTab,
    data.charity1,
    data.charity1Mobile,
    data.charity2,
    data.office1,
    data.office2,
    data.office3,
    data.party1,
    data.party2,
    data.party2Mobile,
    data.relax,
    isMobile,
  ]);

  const weSayList = t("we_say.list", { returnObjects: true });
  const meetTeamList = t("meet_team.list", { returnObjects: true });

  const onMeetTeamButtonClick = (index: number) => {
    setActiveTab(index);
  };

  const tabDescriptionComponent = useMemo(
    () => (
      <div className={styles.meetTeamDescriptionWrapper}>
        {imageBlocks}
        <p className={styles.meetTeamDescriptionText}>{meetTeamList[activeTab].description}</p>
      </div>
    ),
    [activeTab, imageBlocks, meetTeamList],
  );

  return (
    <Layout>
      <section className={styles.teamSection}>
        <div className={styles.teamSectionWrapper}>
          <h1 className={styles.teamTitle}>{t("team_life_about.title")}</h1>
          <p className={styles.teamText}>{t("team_life_about.description")}</p>
        </div>
        <TeamLifeIllustration />
      </section>

      <section className={styles.meetTeamSection}>
        <h2 className={styles.meetTeamTitle}>{t("meet_team.title")}</h2>
        <div className={styles.meetTeamWrapper}>
          <ul className={styles.meetTeamList}>
            {meetTeamList.map(({ title }, index) => {
              const isActive = index === activeTab;
              const listClasses = cn(styles.meetTeamListItem, {
                [styles.meetTeamListItemActive]: isActive,
              });

              return (
                <li key={title} className={listClasses}>
                  <div className={styles.meetTeamListItemWrapper}>
                    <SecondaryArrowIcon />
                    <button
                      className={styles.meetTeamListItemBtn}
                      type="button"
                      onClick={() => onMeetTeamButtonClick(index)}
                    >
                      {title}
                    </button>
                  </div>
                  {isLaptop && tabDescriptionComponent}
                </li>
              );
            })}
          </ul>
          {!isLaptop && tabDescriptionComponent}
        </div>
      </section>

      <section>
        <h2 className={styles.weSayTitle}>{t("we_say.title")}</h2>
        <ul className={styles.weSayList}>
          {weSayList.map((title) => (
            <li key={title} className={styles.weSayListItem}>
              <p className={styles.weSayListItemTitle}>{title}</p>
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.gallerySection}>
        <h2 className={styles.galleryTitle}>{t("we_not_just_colleagues.title")}</h2>
        <Gallery />
      </section>

      <section className={styles.seeYouSection}>
        <div className={styles.seeYouSectionWrapper}>
          <h2 className={styles.seeYouTitle}>
            <Trans i18nKey="see_you_in_team.title">
              We would be happy to <mark className={styles.seeYouTitleMark}>see you in our team</mark>
            </Trans>
          </h2>
          <p className={styles.seeYouText}>{t("see_you_in_team.description")}</p>
        </div>
        <CirclesIllustration />
      </section>

      <CareerBlock />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "teamlife-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    office3: file(relativePath: { eq: "teamlife/office-life-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 436, height: 356, placeholder: BLURRED)
      }
    }
    office2: file(relativePath: { eq: "teamlife/office-life-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 224, height: 170, placeholder: BLURRED)
      }
    }
    office1: file(relativePath: { eq: "teamlife/office-life-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 224, height: 170, placeholder: BLURRED)
      }
    }
    party1: file(relativePath: { eq: "teamlife/party-team-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 436, height: 356, placeholder: BLURRED)
      }
    }
    party2: file(relativePath: { eq: "teamlife/party-team-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 224, height: 356, placeholder: BLURRED)
      }
    }
    party2Mobile: file(relativePath: { eq: "teamlife/party-team-2-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 200, placeholder: BLURRED)
      }
    }
    charity2: file(relativePath: { eq: "teamlife/charity-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 436, height: 356, placeholder: BLURRED)
      }
    }
    charity1: file(relativePath: { eq: "teamlife/charity-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 224, height: 356, placeholder: BLURRED)
      }
    }
    charity1Mobile: file(relativePath: { eq: "teamlife/charity-1-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 200, placeholder: BLURRED)
      }
    }
    relax: file(relativePath: { eq: "teamlife/relax.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 676, height: 356, placeholder: BLURRED)
      }
    }
  }
`;

export default TeamLifePage;
